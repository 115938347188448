var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-spin',{attrs:{"tip":_vm.spinnerLoaderLabel,"size":"large","spinning":_vm.spinnerLoader}},[(!_vm.hasInvoice)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3"},[_c('a-form-item',{attrs:{"label":"Número de ticket"}},[_c('a-input',{directives:[{name:"mask",rawName:"v-mask",value:('#####'),expression:"'#####'"},{name:"decorator",rawName:"v-decorator",value:([
										'id',
										{
											rules: [
												{
													required: true,
													message: 'Ingresa tu número de ticket',
												},
											],
										},
									]),expression:"[\n\t\t\t\t\t\t\t\t\t\t'id',\n\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\t\t\t\tmessage: 'Ingresa tu número de ticket',\n\t\t\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t]"}],attrs:{"autocomplete":"off"}})],1)],1),_c('div',{staticClass:"col-3"},[_c('a-form-item',{attrs:{"label":"Código de facturación"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
										'validator',
										{
											rules: [
												{
													required: true,
													message: 'Ingresa tu código de facturación',
												},
											],
										},
									]),expression:"[\n\t\t\t\t\t\t\t\t\t\t'validator',\n\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\t\t\t\tmessage: 'Ingresa tu código de facturación',\n\t\t\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t]"}],attrs:{"autocomplete":"off"}})],1)],1),_c('div',{staticClass:"col-3"},[_c('a-form-item',{attrs:{"label":"Uso de CFDi"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
										'cfdi_use',
										{
											rules: [
												{
													required: true,
													message: 'Campo requerido.',
												},
											],
										},
									]),expression:"[\n\t\t\t\t\t\t\t\t\t\t'cfdi_use',\n\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\t\t\t\tmessage: 'Campo requerido.',\n\t\t\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t]"}],attrs:{"show-search":""}},_vm._l((_vm.cfdiUseList),function(element,index){return _c('a-select-option',{key:index,attrs:{"value":element.code}},[_vm._v(" "+_vm._s(element.name)+" ")])}),1)],1)],1),_c('div',{staticClass:"col-3"},[_c('a-form-item',{attrs:{"label":"Método de Pago"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
										'payment_method',
										{
											rules: [
												{
													required: true,
													message: 'Campo requerido.',
												},
											],
										},
									]),expression:"[\n\t\t\t\t\t\t\t\t\t\t'payment_method',\n\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\t\t\t\tmessage: 'Campo requerido.',\n\t\t\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t]"}],attrs:{"show-search":""}},_vm._l((_vm.paymentMethods),function(element,key){return _c('a-select-option',{key:key,attrs:{"value":element}},[_vm._v(" "+_vm._s(element)+" - "+_vm._s(_vm.paymentText(key))+" ")])}),1)],1)],1),_c('div',{staticClass:"col-6"},[_c('a-form-item',{attrs:{"label":"Razón Social/Cliente"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
										'razon_social',
										{
											rules: [
												{
													required: true,
													message: 'Ingresa Razón Social',
												},
											],
										},
									]),expression:"[\n\t\t\t\t\t\t\t\t\t\t'razon_social',\n\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\t\t\t\tmessage: 'Ingresa Razón Social',\n\t\t\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t]"}],staticClass:"text-uppercase",attrs:{"autocomplete":"off"}})],1)],1),_c('div',{staticClass:"col-md-6"},[_c('a-form-item',{staticClass:"m0",attrs:{"label":"Régimen Fiscal"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
										'tax_system',
										{
											rules: [
												{
													required: true,
													message: 'Campo requerido.',
												},
											],
										},
									]),expression:"[\n\t\t\t\t\t\t\t\t\t\t'tax_system',\n\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\t\t\t\tmessage: 'Campo requerido.',\n\t\t\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t]"}],attrs:{"showSearch":"","option-filter-prop":"children","filter-option":_vm.filterOption}},_vm._l((_vm.taxRegimes),function(regime){return _c('a-select-option',{key:regime.id,attrs:{"value":regime.id}},[_vm._v(" "+_vm._s(regime.id)+" - "+_vm._s(regime.description)+" ")])}),1)],1)],1),_c('div',{staticClass:"col-4"},[_c('a-form-item',{attrs:{"label":"RFC"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
										'rfc',
										{
											rules: [
												{
													required: true,
													message: 'Ingresa tu RFC',
												},
											],
										},
									]),expression:"[\n\t\t\t\t\t\t\t\t\t\t'rfc',\n\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\t\t\t\tmessage: 'Ingresa tu RFC',\n\t\t\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t]"}],staticClass:"text-uppercase",attrs:{"autocomplete":"off"}})],1)],1),_c('div',{staticClass:"col-4"},[_c('a-form-item',{attrs:{"label":"Código Postal"}},[_c('a-input',{directives:[{name:"mask",rawName:"v-mask",value:('#####'),expression:"'#####'"},{name:"decorator",rawName:"v-decorator",value:([
										'zip',
										{
											rules: [
												{
													required: true,
													message: 'Ingresa Código Postal',
												},
											],
										},
									]),expression:"[\n\t\t\t\t\t\t\t\t\t\t'zip',\n\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\t\t\t\tmessage: 'Ingresa Código Postal',\n\t\t\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t]"}],attrs:{"autocomplete":"off"}})],1)],1),_c('div',{staticClass:"col-4"},[_c('a-form-item',{attrs:{"label":"Email"}},[_c('inputTags',{attrs:{"tags":_vm.emailList,"validateEmail":true},on:{"change":_vm.onChangeEmailReceipts}})],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('hr',{staticClass:"hrText"})]),_c('div',{staticClass:"col-md-4 text-left"},[_c('a-button',{staticClass:"btn btn-warning",on:{"click":_vm.onCancel}},[_vm._v("Limpiar campos")])],1),(!_vm.hasInvoice)?_c('div',{staticClass:"col-md-8 text-right"},[_c('a-button',{staticClass:"btn btn-success",attrs:{"icon":"paper-clip","htmlType":"submit"}},[_vm._v("Solicitar factura")])],1):_vm._e()])])],1)]):_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 text-center"},[_c('h4',{staticClass:"text-muted"},[_vm._v("Tu factura ha sido enviada por correo electrónico.")])])])]),_c('a-modal',{attrs:{"visible":_vm.modalClient.visible,"title":"Búsqueda de Cliente","closable":true,"width":"80%"}},[_c('template',{slot:"footer"},[_c('a-button',{key:"back",on:{"click":_vm.onCloseModal}},[_vm._v(" Cancelar ")]),_c('a-button',{key:"submit",staticClass:"btn btn-success",on:{"click":_vm.addClient}},[_vm._v(" Seleccionar ")])],1),_c('clientsQuickSearchComponent',{ref:"clientsQuickSearchComponent",attrs:{"visible":_vm.modalClient.visible,"showAddButton":false},on:{"selectedRecord":_vm.onSelectedClient}})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }