<template>
	<div class="tag-input">
		<a-input autocomplete="off" v-model="inputText" :placeholder="placeholder" @keydown.enter="addTag" @keydown.188="addTag" @keydown.delete="removeLastTag" />
		<div v-for="(tag, index) in localTags" :key="index" class="tag-input__tag">
			<span @click="removeTag(index)">x</span>
			{{ tag }}
			<br />
		</div>
	</div>
</template>
<script>
import _ from 'lodash'
import utilities from '@/services/utilities'

export default {
	props: {
		placeholder: {
			type: String,
			default: '',
		},
		tags: {
			type: Array,
			default: () => {
				return []
			},
		},
		validateEmail: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			inputText: '',
			localTags: [],
		}
	},
	mounted() {
		this.localTags = _.cloneDeep(this.tags)
	},
	methods: {
		// Añade un elemento al ingresar coma o enter
		addTag(event) {
			event.preventDefault()
			var val = this.inputText.trim()

			// validamos si el texto ingresado es email válido
			if (this.validateEmail && !utilities.validateEmail(val)) {
				return false
			}

			if (val.length > 0) {
				this.localTags.push(val)
				this.inputText = ''
			}
		},
		removeTag(index) {
			if (this.localTags.length > 0) {
				this.localTags.splice(index, 1)
			}
		},
		removeLastTag(event) {
			if (event.target.value.length === 0) {
				this.removeTag(this.localTags.length - 1)
			}
		},
	},
	watch: {
		localTags() {
			this.$emit('change', this.localTags)
		},
	},
}
</script>
<style scoped>
.tag-input__tag {
	height: 30px;
	float: left;
	margin-right: 10px;
	background-color: #000000;
	color: #ffffff;
	margin-top: 10px;
	line-height: 30px;
	padding: 0 5px;
	border-radius: 5px;
}

.tag-input__tag > span {
	cursor: pointer;
	opacity: 0.75;
}

.tag-input__text {
	border: none;
	outline: none;
	font-size: 0.9em;
	line-height: 50px;
	background: none;
}
</style>