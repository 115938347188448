<template>
	<div>
		<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
			<div class="row" v-if="!hasInvoice">
				<div class="col-md-12">
					<a-form :form="form" @submit="handleSubmit">
						<div class="row">
							<div class="col-3">
								<a-form-item label="Número de ticket">
									<a-input
										autocomplete="off"
										v-mask="'#####'"
										v-decorator="[
											'id',
											{
												rules: [
													{
														required: true,
														message: 'Ingresa tu número de ticket',
													},
												],
											},
										]"
									/>
								</a-form-item>
							</div>
							<div class="col-3">
								<a-form-item label="Código de facturación">
									<a-input
										autocomplete="off"
										v-decorator="[
											'validator',
											{
												rules: [
													{
														required: true,
														message: 'Ingresa tu código de facturación',
													},
												],
											},
										]"
									/>
								</a-form-item>
							</div>
							<div class="col-3">
								<a-form-item label="Uso de CFDi">
									<a-select
										show-search
										v-decorator="[
											'cfdi_use',
											{
												rules: [
													{
														required: true,
														message: 'Campo requerido.',
													},
												],
											},
										]"
									>
										<a-select-option v-for="(element, index) in cfdiUseList" :key="index" :value="element.code"> {{ element.name }} </a-select-option>
									</a-select>
								</a-form-item>
							</div>
							<div class="col-3">
								<a-form-item label="Método de Pago">
									<a-select
										show-search
										v-decorator="[
											'payment_method',
											{
												rules: [
													{
														required: true,
														message: 'Campo requerido.',
													},
												],
											},
										]"
									>
										<a-select-option v-for="(element, key) in paymentMethods" :key="key" :value="element"> {{ element }} - {{ paymentText(key) }} </a-select-option>
									</a-select>
								</a-form-item>
							</div>
							<div class="col-6">
								<a-form-item label="Razón Social/Cliente">
									<a-input
										autocomplete="off"
										class="text-uppercase"
										v-decorator="[
											'razon_social',
											{
												rules: [
													{
														required: true,
														message: 'Ingresa Razón Social',
													},
												],
											},
										]"
									/>
								</a-form-item>
								<!-- <small class="pointer" @click="onShowModal">Buscar cliente</small> -->
							</div>
							<div class="col-md-6">
								<a-form-item class="m0" label="Régimen Fiscal">
									<a-select
										showSearch
										option-filter-prop="children"
										:filter-option="filterOption"
										v-decorator="[
											'tax_system',
											{
												rules: [
													{
														required: true,
														message: 'Campo requerido.',
													},
												],
											},
										]"
									>
										<a-select-option v-for="regime in taxRegimes" :key="regime.id" :value="regime.id"> {{ regime.id }} - {{ regime.description }} </a-select-option>
									</a-select>
								</a-form-item>
							</div>
							<div class="col-4">
								<a-form-item label="RFC">
									<a-input
										autocomplete="off"
										class="text-uppercase"
										v-decorator="[
											'rfc',
											{
												rules: [
													{
														required: true,
														message: 'Ingresa tu RFC',
													},
												],
											},
										]"
									/>
								</a-form-item>
							</div>
							<div class="col-4">
								<a-form-item label="Código Postal">
									<a-input
										autocomplete="off"
										v-mask="'#####'"
										v-decorator="[
											'zip',
											{
												rules: [
													{
														required: true,
														message: 'Ingresa Código Postal',
													},
												],
											},
										]"
									/>
								</a-form-item>
							</div>
							<div class="col-4">
								<a-form-item label="Email">
									<inputTags :tags="emailList" :validateEmail="true" @change="onChangeEmailReceipts" />
								</a-form-item>
							</div>
						</div>
						<div class="row">
							<div class="col-md-12">
								<hr class="hrText" />
							</div>
							<div class="col-md-4 text-left">
								<a-button class="btn btn-warning" @click="onCancel">Limpiar campos</a-button>
							</div>
							<div class="col-md-8 text-right" v-if="!hasInvoice">
								<a-button icon="paper-clip" class="btn btn-success" htmlType="submit">Solicitar factura</a-button>
							</div>
						</div>
					</a-form>
				</div>
			</div>
			<div class="row" v-else>
				<div class="col-md-12 text-center">
					<h4 class="text-muted">Tu factura ha sido enviada por correo electrónico.</h4>
				</div>
			</div>
		</a-spin>
		<!-- Búsqueda de cliente -->
		<a-modal :visible="modalClient.visible" title="Búsqueda de Cliente" :closable="true" width="80%">
			<template slot="footer">
				<a-button key="back" @click="onCloseModal"> Cancelar </a-button>
				<a-button key="submit" class="btn btn-success" @click="addClient"> Seleccionar </a-button>
			</template>
			<clientsQuickSearchComponent ref="clientsQuickSearchComponent" :visible="modalClient.visible" :showAddButton="false" @selectedRecord="onSelectedClient" />
		</a-modal>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import { mask } from 'vue-the-mask'
import { paymentMethods } from '@/constants'
import utilities from '@/services/utilities'
import inputTags from '@/components/inputTags'
import clientsQuickSearchComponent from '@/components/clients/quickSearch'
import { taxRegimes } from '@/constants'

export default {
	name: 'billingComponent',
	components: {
		inputTags,
		clientsQuickSearchComponent,
	},
	directives: {
		mask,
	},
	computed: {
		...mapGetters('billing', ['invoice', 'spinnerLoaderLabel', 'spinnerLoader']),
		...mapGetters('pos', ['actualTicket']),
		hasInvoice() {
			return !!this.invoice
		},
	},
	data: function () {
		return {
			form: this.$form.createForm(this),
			cfdiUseList: [
				{ id: 1, name: 'G01 - Adquisición de mercancías', code: 'G01' },
				{ id: 3, name: 'G03 - Gastos en general', code: 'G03' },
				// { id: 22, name: 'P01 - Por Definir', code: 'P01' },
			],
			paymentMethods,
			emailList: [],
			modalClient: {
				visible: false,
			},
			taxRegimes,
		}
	},
	mounted() {
		this.form.setFieldsValue({
			id: utilities.objectValidate(this.actualTicket, 'id', ''),
			validator: utilities.objectValidate(this.actualTicket, 'validator', ''),
		})
	},
	destroyed() {
		this.onCancel()
		this.$store.commit('pos/SET_STATE', {
			actualTicket: {},
		})
	},
	methods: {
		paymentText(text) {
			return utilities.replaceText(text, '_', ' ')
		},
		handleSubmit(e) {
			e.preventDefault()
			this.form.validateFields((err, values) => {
				if (!err) {
					let payload = {
						...values,
						email: this.emailList,
						razon_social: values.razon_social.toUpperCase(),
						rfc: values.rfc.toUpperCase(),
						tax_system: values.tax_system.toString(),
					}
					this.$store.dispatch('billing/CREATE_BILL', payload)
				}
			})
		},
		downloadBill(type) {
			let payload = {
				type,
				id: this.invoice,
			}
			this.$store.dispatch('billing/DOWNLOAD_BILL', payload)
		},
		onCancel() {
			this.form.resetFields()
			this.emailList = []
		},
		onChangeEmailReceipts(payload) {
			this.emailList = _.cloneDeep(payload)
		},
		onShowModal() {
			this.modalClient.visible = true
		},
		onCloseModal() {
			this.modalClient.visible = false
		},
		addClient() {
			this.$refs.clientsQuickSearchComponent.onSelectedRecord()
		},
		onSelectedClient(payload) {
			console.log('onSelectedClient-->', payload)
		},
		filterOption(input, option) {
			return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
		},
	},
	watch: {
		hasInvoice() {
			setTimeout(() => {
				this.$store.commit('billing/SET_STATE', {
					invoice: '',
				})
				this.onCancel()
			}, 2500)
		},
		actualTicket: {
			deep: true,
			handler(newData) {
				if (newData) {
					this.onCancel()
					this.form.setFieldsValue({
						id: utilities.objectValidate(this.actualTicket, 'id', ''),
						validator: utilities.objectValidate(this.actualTicket, 'validator', ''),
					})
				}
			},
		},
	},
}
</script>
<style lang="scss" module>
@import './style.module.scss';
</style>